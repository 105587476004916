<template>
  <main v-if="loading" id="js-page-content" class="page-content" role="main">
    <div class="subheader hidden-lg-up">
      <portal to="ariane">
        <MultipleListCreate></MultipleListCreate>
      </portal>
    </div>

    <!-- Grille 4 colonnes-->
    <div class="w-full flex items-center justify-center">
      <div
        class="w-full grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-5"
      >
        <ChartPie
          :titleChart="$t(`post.page.statistics.title.solved`)"
          headingChart=""
          chartId="chartIdOpenResolved"
          :chartData="chartDataOpenResolved"
        />
        <ChartPie
          :titleChart="$t(`post.page.statistics.title.answered`)"
          headingChart=""
          chartId="chartIdResponse"
          :chartData="chartDataResponse"
        />
        <ChartPie
          :titleChart="$t(`post.page.statistics.title.assigned`)"
          headingChart=""
          chartId="chartIdAssigned"
          :chartData="chartDataAssigned"
        />
        <ChartPie
          :titleChart="$t(`post.page.statistics.title.bystatusopened`)"
          headingChart=""
          chartId="chartIdStatus"
          :chartData="chartDataStatus"
        />
      </div>
    </div>

    <div class="mt-5 gap-5 grid grid-cols-1 md:grid-cols-2 items-stretch">
      <BarChart
        :titleChart="$t(`post.page.statistics.title.bymonth`)"
        headingChart=""
        chartId="barChartIdMois"
        :chartData="barChartDataMois"
      />
      <BarChart
        :titleChart="$t(`post.page.statistics.title.byweek`)"
        headingChart=""
        chartId="barChartIdSemaine"
        :chartData="barChartData"
      />
      <!-- <BarChart
        titleChart="Tickets par jour"
        chartId="barChartIdJour"
        :chartData="barChartDataJour"
      /> -->
    </div>
  </main>
</template>

<script>
import Panel from '../../components/UI/Panel'
import MultipleListCreate from '@/components/UI/MultipleListCreate'
import ChartPie from '@/components/UI/chartPie'
import BarChart from '@/components/UI/BarChart'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Statistiques',
  components: { Panel, MultipleListCreate, ChartPie, BarChart },
  data() {
    return {
      loading: true,
      statistics: null,
      chartDataOpenResolved: {
        type: 'pie',
        data: {
          labels: [],
          datasets: [
            {
              borderColor: ['#fff ', '#fff'],
              backgroundColor: ['#3B82F6 ', '#10B981'],
              data: [],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            datalabels: {
              color: '#FFFFFF',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
        },
      },
      chartDataResponse: {
        type: 'pie',
        data: {
          labels: [],
          datasets: [
            {
              borderColor: ['#fff ', '#fff'],
              backgroundColor: ['#EF4444 ', '#10B981'],
              data: [],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            datalabels: {
              color: '#FFFFFF',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
        },
      },
      chartDataAssigned: {
        type: 'pie',
        data: {
          labels: ['Non assignés', 'Assignés'],
          datasets: [
            {
              borderColor: ['#fff ', '#fff'],
              backgroundColor: ['#EF4444 ', '#10B981'],
              data: [30, 67],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            datalabels: {
              color: '#FFFFFF',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
        },
      },
      chartDataStatus: {
        type: 'pie',
        data: {
          labels: [],
          datasets: [
            {
              borderColor: ['#fff ', '#fff', '#fff'],
              backgroundColor: ['#FBBF24', '#3B82F6', '#10B981'],
              data: [],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            datalabels: {
              color: '#FFFFFF',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
        },
      },
      barChartData: {
        type: 'bar',
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              fill: false,
              backgroundColor: '#3B82F6',
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: '#FFFFFF',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
        },
      },
      barChartDataMois: {
        type: 'bar',
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              fill: false,
              backgroundColor: '#3B82F6',
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: '#FFFFFF',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
        },
      },
      // barChartDataJour: {
      //   type: 'bar',
      //   data: {
      //     labels: ['9h', '10h', '14h', '15h', '16h', '17h', '18h'],
      //     datasets: [
      //       {
      //         data: [],
      //         fill: false,
      //         backgroundColor: '#3B82F6',
      //       },
      //     ],
      //   },
      //   options: {
      //     legend: {
      //       display: false,
      //     },
      //     plugins: {
      //       datalabels: {
      //         color: '#FFFFFF',
      //         font: {
      //           weight: 'bold',
      //           size: 16,
      //         },
      //       },
      //     },
      //   },
      // },
    }
  },
  computed: {
    ...mapState('post', {
      config: (state) => state.postsConfig,
    }),
    ...mapState('status', {
      statuses: (state) => state.statuses,
    }),
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('post', ['getVolumetry']),
    addData(chart, label, data) {
      chart.data.labels.push(label)
      chart.data.datasets[0].data.push(data)
    },
    renameLabelSolved(label) {
      return this.$t(`post.page.statistics.label.solved.${label}`)
    },
    renameLabelAnswered(label) {
      return this.$t(`post.page.statistics.label.answered.${label}`)
    },
    renameLabelWeek(label) {
      return this.$t(`post.page.statistics.label.weekday.${label}`)
    },
    renameLabelMonth(label) {
      return this.$t(`post.page.statistics.label.month.${label}`)
    },
    getData() {
      this.getVolumetry()
        .then((response) => {
          this.loading = false
          this.statistics = response.data
          this.statistics.countStatusSolved.forEach((el) =>
            this.addData(
              this.chartDataOpenResolved,
              this.renameLabelSolved(el['solved']),
              el['count']
            )
          )
          this.statistics.countWithResponses.forEach((el) =>
            this.addData(
              this.chartDataResponse,
              this.renameLabelAnswered(el['answered']),
              el['count']
            )
          )
          this.statistics.countStatusOpened.forEach((el) =>
            this.addData(this.chartDataStatus, el['name'], el['count'])
          )
          this.statistics.countbyMonth.forEach((el) =>
            this.addData(
              this.barChartDataMois,
              this.renameLabelMonth(el['month']),
              el['count']
            )
          )
          this.statistics.countbyDayofWeek.forEach((el) =>
            this.addData(
              this.barChartData,
              this.renameLabelWeek(el['day_of_week']),
              el['count']
            )
          )
        })
        .catch(
          // requete rejetée
          function () {
            console.log('req rompue')
          }
        )
        .finally(() => {
          this.loading = true
        })
    },
  },
}
</script>
